$(function () {
  init();

  function init() {
    var $form = $('#form');
    $form.submit(function () {
      $.ajax({
        url: $form.attr('action'),
        data: $form.serialize(),
        type: 'POST',
        dataType: 'json',
        success: function (json) {
          var status = json.status;
          if (status === 'success') {
            $('#message').html('<h3>お問い合わせの受付を完了しました。ありがとうございました。</h3>');
          } else if (status === 'failed') {
            $('#message').html('<h3>お問い合わせの受付に失敗しました。メールアドレスに間違いがないかご確認ください。</h3>');
          } else {
            var errors = json.err_msg;
            var msg = '';
            for (var i = 0; i < errors.length; i++) {
              msg += '<li>' + errors[i] + '</li>';
            }
            $('#message').html('<ul class="c-list c-list--point u-mt20">' + msg + '</ul>');
          }
        }
      });
      return false;
    });
  }
});